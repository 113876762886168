<template>
  <div class="main-content">
    <h3 class="marginBottom20">查看二手房-{{ form.applyState === 2 ? '待审核' : form.applyState === 3 ? '审核通过' : form.applyState === 4 ? '审核驳回' : '/' }}</h3>
    <avue-form ref="form"
               v-model="form"
               v-loading="loading"
               :option="formOption">
      <template slot="titleOne">
        <div class="flex align-items">
          <h3>基础信息</h3>
          <div class="width300"></div>
          <el-button v-if="form.applyState === 2" type="text">待审核</el-button>
          <el-button v-if="form.applyState === 3" style="color: #70B603" type="text">审核通过</el-button>
          <el-button v-if="form.applyState === 4" style="color: #D9001B" type="text">审核驳回</el-button>
        </div>
      </template>
      <template slot="title"><div>{{ form.title }}</div></template>
      <template slot="cityId"><div>{{ cityName }}{{ countyName }}</div></template>
      <template slot="businessId"><div>{{ businessName }}</div></template>
      <template slot="buildId"><div>{{ buildName }}</div></template>
      <template slot="buildNum"><div>{{ form.buildNum }} {{ form.isUnit === 1 ? form.unitNumber : '' }} {{ form.isHouse === 1 ? form.houseNumber : '' }}</div></template>
      <template slot="bedroom"><div>{{ form.bedroom }} 室 {{ form.office }} 厅</div></template>
      <template slot="floorage"><div>{{ form.floorage }} 平米</div></template>
      <template slot="sellPrice"><div>{{ form.sellPrice }} 万元</div></template>
      <template slot="buildYear">
        <div>{{ form.buildYear }}</div>
        <el-button type="text" style="color: #ebb563">实际房龄以政府信息为准</el-button>
      </template>
      <template slot="labelIds"><div>{{ labelName }}</div></template>
      <template slot="titleTwo"><h3>房源信息</h3></template>
      <template slot="orientation"><div>{{ orientationText }}</div></template>
      <template slot="floor"><div>{{ floorText }}</div></template>
      <template slot="decorate"><div>{{ form.decorate === 1 ? '精装' : form.decorate === 2 ? '简装' : '/' }}</div></template>
      <template slot="elevator"><div>{{ form.elevator || '/' }}</div></template>
      <template slot="buildType"><div>{{ form.buildType === 1 ? '板楼' : form.buildType === 2 ? '塔楼' : form.buildType === 3 ? '板塔结合' : '/' }}</div></template>
      <template slot="purpose"><div>{{ form.purpose === 1 ? '普通住宅' : form.purpose === 2 ? '办公' : form.purpose === 3 ? '商住' : '/' }}</div></template>
      <template slot="ownership"><div>{{ form.ownership === 1 ? '个人单独产权' : form.ownership === 2 ? '个人多人产权' : form.ownership === 3 ? '公司产权' : '/' }}</div></template>
      <template slot="mortgage"><div>{{ form.mortgage === 1 ? '无抵押' : form.mortgage === 2 ? '只有银行抵押' : form.mortgage === 3 ? '有银行及其他抵押' : '/' }}</div></template>
      <template slot="heatMode"><div>{{ form.heatMode === 1 ? '无' : form.heatMode === 2 ? '自供暖' : form.heatMode === 3 ? '集中供暖' : '/' }}</div></template>
      <template slot="ageLimit"><div>{{ form.ageLimit === 1 ? '不满2年' : form.ageLimit === 2 ? '满2年' : form.ageLimit === 3 ? '满5年' : '/' }}</div></template>
      <template slot="lastTime"><div>{{ form.lastTime }}</div></template>
      <template slot="titleThree"><h3>房源资料</h3></template>
      <template slot="titleFour"><div></div></template>
      <template slot="houseOwner"><div>{{ form.houseOwner }}</div></template>
      <template slot="phone"><div>{{ form.phone || '/'}}</div></template>
      <template slot="idCard"><div>{{ form.idCard }}</div></template>
      <template slot="signType"><div>{{ form.signType === 1 ? '线上签订' : form.signType === 2 ? '手动上传授权委托书' : '/' }}</div></template>
      <template slot="signTypeOne">
        <el-button style="background: #c280ff;color: #ffffff;border-color: #c280ff" @click="viewAgreement">查看协议</el-button>
      </template>
      <template slot="rejectReason">
        <div class="flex align-items" style="color: #D9001B">
          <div style="width: 200px;text-align: right;padding-right: 10px;box-sizing: border-box">驳回原因: </div>
          <div>{{ form.rejectReason }}</div>
        </div>
      </template>

      <template slot="menuForm">
        <el-button style="padding: 9px 40px" @click="handleBack">返 回</el-button>
        <el-button v-if="form.applyState === 2" style="color: #ffffff;background: #f59a23;border-color: #f59a23;padding: 9px 40px" @click="handleExamine">审 核</el-button>
      </template>
    </avue-form>

    <Examine v-if="show" :show="show" :examine="examine" @submit="submitExamine" @close="closeExamine"/>
  </div>
</template>

<script>
import { view } from "@/api/house/secondExamine"
import {listBuild, listBusiness, listCity, listCounty, listLabel, viewContract} from '@/api/house/second'
import {floor, orientation} from "./data";
import Examine from "./examine";
export default {
  name: "detail",
  components: {
    Examine
  },
  data(){
    return{
      cityName: '',
      countyName: '',
      businessName: '',
      buildName: '',
      labelName: '',
      orientation: orientation,
      orientationText: '',
      floor: floor,
      floorText: '',
      form: {},
      loading: false,
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        disabled: true,
        column: [
          {
            label: '',
            prop: 'titleOne',
            labelWidth: 100,
            span: 24,
          },
          {
            label: '房源标题',
            prop: 'title',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '城市',
            prop: 'cityId',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '商圈',
            prop: 'businessId',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '楼盘名称',
            prop: 'buildId',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '房屋地址',
            prop: 'buildNum',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '户型',
            prop: 'bedroom',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '建筑面积',
            prop: 'floorage',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '期望售价',
            prop: 'sellPrice',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '建筑年代',
            prop: 'buildYear',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '标签',
            prop: 'labelIds',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '',
            prop: 'titleTwo',
            labelWidth: 100,
            span: 24,
          },
          {
            label: '朝向',
            prop: 'orientation',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '楼层',
            prop: 'floor',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '装修',
            prop: 'decorate',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '电梯',
            prop: 'elevator',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '楼型',
            prop: 'buildType',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '用途',
            prop: 'purpose',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '权属',
            prop: 'ownership',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '抵押情况',
            prop: 'mortgage',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '供暖方式',
            prop: 'heatMode',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '房本年限',
            prop: 'ageLimit',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '上次交易',
            prop: 'lastTime',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '',
            prop: 'titleThree',
            labelWidth: 100,
            span: 24,
          },
          {
            label: '房源图片上传',
            prop: 'images',
            type: 'upload',
            span: 24,
            dataType: 'string',
            listType: 'picture-card',
            rules: [{ required: true }]
          },
          {
            label: '房主身份证信息',
            prop: 'titleFour',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '房主姓名',
            prop: 'houseOwner',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '房主手机号',
            prop: 'phone',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '身份证号',
            prop: 'idCard',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '房主身份证正反面',
            prop: 'idCardFront',
            type: 'upload',
            span: 24,
            dataType: 'string',
            listType: 'picture-card',
            rules: [{ required: true }]
          },
          {
            label: '',
            prop: 'idCardBack',
            type: 'upload',
            span: 24,
            dataType: 'string',
            listType: 'picture-card',
            rules: [{ required: true }]
          },
          {
            label: '房本上传',
            prop: 'premisesPermit',
            type: 'upload',
            span: 24,
            dataType: 'string',
            listType: 'picture-card',
            rules: [{ required: true }]
          },
          {
            label: '签订挂牌委托协议',
            prop: 'signType',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '',
            prop: 'signTypeOne',
            span: 24,
            display: false
          },
          {
            label: '',
            prop: 'signTypeImage',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            span: 24,
            display: false
          },
          {
            label: '',
            prop: 'rejectReason',
            labelWidth: 0,
            span: 12,
            display: false
          }
        ]
      },

      show: false,
      examine: {
        id: this.$route.query.id
      }
    }
  },
  async mounted(){
    this.loading = true;
    await this.getView();
  },
  methods: {
    async getView(){
      const { data } = await view({
        id: this.$route.query.id
      });

      const rejectReason = this.findObject(this.formOption.column, 'rejectReason');
      if(data.applyState === 4){
        rejectReason.display = true
      }

      // console.log(data);
      let arrayA = data.orientation.split(',');
      let arrayB = arrayA.map(item=>Number(item))
      console.log(arrayB)
      this.form = data;
      this.orientationText = this.orientation.map(item=>{
        if(arrayB.includes(item.value)){
          return item.label
        }
      }).filter(item=>item).join(';');
      this.floorText = this.floor.map(item=>{
        if(item.value === data.floor){
          return item.label;
        }
      }).filter(item=>item).join(';');
      const signTypeOne = this.findObject(this.formOption.column, 'signTypeOne');
      const signTypeImage = this.findObject(this.formOption.column, 'signTypeImage');
      if(data.signType === 1){
        signTypeOne.display = true;
        signTypeImage.display = false;
      }else if(data.signType === 2){
        signTypeOne.display = false;
        signTypeImage.display = true;
      }else{
        signTypeOne.display = false;
        signTypeImage.display = false;
      }

      await this.getListLabel();
      await this.getListCity();
    },
    // 城市列表
    async getListCity(){
      const { data } = await listCity();
      // console.log(data);
      this.cityName = data.map(item => {
        if(item.id === this.form.cityId){
          return item.name;
        }
      }).join('');
      await this.getListCounty(this.form.cityId);
    },
    // 区域列表
    async getListCounty(id){
      const { data } = await listCounty({id: id});
      // console.log(data)
      this.countyName = data.map(item => {
        if(item.id === this.form.countyId){
          return item.name;
        }
      }).join('');
      await this.getListBusiness(this.form.countyId);
    },
    // 商圈列表
    async getListBusiness(id){
      const { data } = await listBusiness({id: id});
      // console.log(data)
      this.businessName = data.map(item => {
        if(item.id === this.form.businessId){
          return item.name;
        }
      }).join('');
      await this.getListBuild();
    },
    // 商圈列表
    async getListBuild(){
      const { data } = await listBuild({
        cityId: this.form.cityId,
        countyId: this.form.countyId,
        businessId: this.form.businessId
      })
      // console.log(data)
      this.buildName = data.map(item => {
        if(item.id === this.form.buildId){
          return item.name;
        }
      }).join('');
      // 数据加载完成
      this.loading = false;
    },
    // 标签列表
    async getListLabel(){
      const { data } = await listLabel()
      // console.log(data)
      const name = data.map(item => {
        if(this.form.labelIds.includes(item.id)){
          return item.name;
        }
      })
      this.labelName = name.filter(item => item).join(';');
    },
    // 查看协议
    async viewAgreement() {
      const {data} = await viewContract({
        id: this.form.contractId
      })
      // console.log(data)
      window.open(data.pageUrl, '_blank')
    },
    // 审核
    handleExamine(row){
      console.log('审核',row)
      this.show = true;
    },
    async submitExamine(){
      this.show = false;
      this.loading = true;
      await this.getView();
    },
    closeExamine(){
      this.show = false;
    },
    // 返回
    handleBack(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>