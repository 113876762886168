export const option = {
    title: '二手房房源审核列表',
    titleSize: 'h3',
    card: true,
    border: true,
    header: false,
    stripe: false, // 显示斑马纹
    showHeader: true, // 显示表头
    index: true, // 显示索引
    indexLabel: '序号', // 索引文本
    indexWidth: 100, // 索引宽度
    size: 'small',
    selection: false, // 显示多选框
    page: true, // 显示分页
    align: 'center',
    menuAlign: 'center',
    menuType: 'text',
    editBtn: false,
    delBtn: false,
    column: [
        {
            label: '二手房房源号',
            prop: 'code',
            width: 200
        },
        {
            label: '房源标题',
            prop: 'title',
            width: 200
        },
        {
            label: '城市',
            prop: 'cityName',
            width: 150
        },
        {
            label: '区县',
            prop: 'countyName',
            width: 150
        },
        {
            label: '商圈',
            prop: 'businessName',
            width: 150
        },
        {
            label: '楼盘名称',
            prop: 'buildName',
            width: 150
        },
        {
            label: '售价(万元)',
            prop: 'sellPrice',
            width: 150
        },
        {
            label: '户型',
            prop: 'bedroom',
            formatter: (row) => {
                return row.bedroom + '室' + row.office + '厅'
            },
            width: 150
        },
        {
            label: '建筑面积(㎡)',
            prop: 'floorage',
            width: 150
        },
        {
            label: '标签',
            prop: 'labelName',
            width: 200
        },
        {
            label: '录入角色',
            prop: 'entryRole',
            formatter: function(row, column, cellValue){
                return cellValue === 1 ? '平台' : cellValue === 2 ? '业主' : cellValue === 3 ? '认证经纪人' : cellValue === 4 ? '八爪鱼抓取' : '/';
            },
            width: 150
        },
        {
            label: '录入人名称',
            prop: 'nickname',
            formatter: function(row, column, cellValue){
                return cellValue ? cellValue : '/';
            },
            width: 150
        },
        {
            label: '审核状态',
            prop: 'applyState',
            formatter: function(row, column, cellValue){
                return cellValue === 2 ? '待审核' : cellValue === 3 ? '审核通过' : cellValue === 4 ? '审核驳回' : '/';
            },
            width: 150
        },
        {
            label: '驳回原因',
            prop: 'rejectReason',
            formatter: function(row, column, cellValue){
                return cellValue ? cellValue : '/';
            },
            width: 200
        }
    ]
}
export const orientation = [
    {
        label: '东',
        value: 1
    },
    {
        label: '南',
        value: 2
    },
    {
        label: '西',
        value: 3
    },
    {
        label: '北',
        value: 4
    }
]
export const floor = [
    {
        label: '顶层',
        value: 1
    },
    {
        label: '高层',
        value: 2
    },
    {
        label: '中层',
        value: 3
    },
    {
        label: '低层',
        value: 4
    },
    {
        label: '底层',
        value: 5
    }
]